import React from "react";
import Meta from "./../components/Meta";

function IndexPage(props) {
  return (

    <>
      <Meta />
      <section className="py-12 px-4">
          <div style={{paddingTop:"30px", marginRight:"10%", marginLeft:"10%"}} className="ml-10 mr-10 justify-center items-center">
              <div className="justify-center items-center" style={{margin:"auto"}}><h1 className="text-center mb-4 text-3xl font-extrabold text-gray-600 dark:text-gray-600 md:text-5xl lg:text-6xl">Welcome to <span
                  className="text-transparent bg-clip-text bg-gradient-to-r to-teal-800 from-teal-400">S4Avant.com</span></h1>
                  <div style={{margin:"auto"}} className="text-center justify-center items-center"> Welcome to our website for the S4 Avant swap! We'll be building this site soon. For now, it just hosts images. ;)
                  </div>
                  <div style={{margin:"auto"}} className="text-center justify-center items-center">We've added a bunch of parts <a className="text-teal-600" href="/parts">for sale here</a></div>
              </div>
          </div>

      </section>
      {/*<NewsletterSection />*/}
    </>
  );
}

export default IndexPage;
