import React, {useState, useEffect, useRef} from 'react';
import { createClient } from '@supabase/supabase-js';
import Carousel from "nuka-carousel";
import { useLocation } from 'react-router-dom';


const SUPABASE_URL = 'https://ffmhshwetwqzoabktpln.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZmbWhzaHdldHdxem9hYmt0cGxuIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEyMzkzMTcsImV4cCI6MTk5NjgxNTMxN30.KoPYU3K-RjEOmN6RPKLXiLPUZW-L_MuO4S4NNM-Jw54';

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

function Parts() {
    const [allPartData, setAllPartData] = useState([]);
    const [filteredPartData, setFilteredPartData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [partToDisplay, setPartToDisplay] = useState([]);
    const [carouselKey, setCarouselKey] = useState(0);
    const [description, setDescription] = useState('');
    const dialogRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const queryString = location.search; // Get the query string from the URL
        const urlParams = new URLSearchParams(queryString); // Parse the query string
        const partNumberValue = urlParams.get('partNumber'); // Get the "partNumber" query parameter

        if(partNumberValue != null) {
            setSearch(partNumberValue);
            setPage(0);
        }

    }, [location]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setShowDialog(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dialogRef, setShowDialog]);


    useEffect(() => {
        async function fetchParts() {
            const { data, error } = await supabase.from('parts').select('*').eq('item_available', true);

            if (error) {
                console.error('Error fetching parts:', error);
                return;
            }

            const groupedData = groupByPartNumber(data);
            setAllPartData(groupedData);

        }
        fetchParts();
    }, []);

    useEffect(() => {
        function filterParts() {
            const filteredParts = allPartData.filter((parts) =>
                parts[0].part_number.toLowerCase().includes(search.toLowerCase())
            );
            setFilteredPartData(filteredParts);
        }
        filterParts();
    }, [search, allPartData]);

    useEffect(() => {
        function paginateParts() {
            const start = page * 12;
            const end = start + 12;
            setPartData(filteredPartData.slice(start, end));
        }
        paginateParts();
    }, [filteredPartData, page]);



    function groupByPartNumber(parts) {
        const groupedParts = parts.reduce((acc, part) => {
            if (!acc[part.part_number]) {
                acc[part.part_number] = [];
            }
            acc[part.part_number].push(part);
            return acc;
        }, {});

        return Object.values(groupedParts);
    }

    function handleSearchChange(e) {
        setSearch(e.target.value);
        setPage(0);
    }

    function handlePageChange(newPage) {
        setPage(newPage);
        setTimeout(() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }, 600);
    }

    const handleClick = (e) => {
        setShowDialog(true);
        const  desc = JSON.stringify(e[0].description_body.replace(/\\n/g, ''));
        setDescription(desc);
        setPartToDisplay(e);
        setCarouselKey(carouselKey + 1);
    };

    return (
        <>
            <div style={{ paddingTop: '30px' }} className='justify-center items-center'>
                <div style={{paddingTop:"30px"}} className="justify-center items-center">
                    <div className="justify-center items-center w-1/2" style={{margin:"auto"}}><h1 className="text-center mb-4 text-3xl font-extrabold text-gray-600 dark:text-gray-600 md:text-5xl lg:text-6xl">Welcome to <span
                        className="text-transparent bg-clip-text bg-gradient-to-r to-teal-800 from-teal-400">S4Avant.com</span></h1>
                        <p style={{marginRight:"10%", marginLeft:"10%"}} className="text-center justify-center items-center ml-4 mr-4 mb-8 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">As we swapped all of the components from an S4 into an A4 Avant, we have a surplus of extra parts that we need to get rid of. Your support will help us continue the build and produce even more cool content.</p>

                    </div></div>
                <div className="flex  justify-center mb-6 mx-auto place-items-center items-center ">
                    <div className=" items-center">
                        <label
                        htmlFor="fname"
                        className='text-gray-500 font-bold mb-1 md:mb-0 whitespace-nowrap mr-2'

                        >Search by part number: </label>
                    </div>
                    <div className=" items-center">

                        <input
                        type='text'
                        value={search}
                        onChange={handleSearchChange}
                        className='appearance-none border-2 border-teal-200 rounded w-full py-2 px-4 text-teal-700 leading-tight focus:outline-none focus:bg-white focus:border-teal-800'
                        placeholder='Ex: 8T0947135H'
                        />
                    </div>
                </div>



            </div>
            {showDialog && (
                <div
                    style={showDialog ? { backdropFilter: 'blur(10px)' } : {paddingRight:'10px'}}
                    className='fixed top-0 left-0 w-full  h-full outline-none overflow-x-hidden overflow-y-auto flex h-screen justify-center items-center'
                    aria-modal='true'
                    id="test"
                    onClick={() => showDialog(false)}
                >
                    <div style={{borderRadius:'10px',padding:'10px'}} className="bg-teal-500">
                        <div  className="bg-teal-500 modal-dialog modal-xl  w-auto pointer-events-auto"  ref={dialogRef}>

                            <Carousel
                                key={carouselKey}
                                wrapAround={true}
                                adaptiveHeight
                                adaptiveHeightAnimation
                                adaptiveWidth
                                slidesToShow={1}
                                style={{marginRight:'10px',pading:'10px'}}>
                                {partToDisplay.map((image, index) => (
                                    <>
                                        <img style={{ margin: 'auto' }} className='bg-white' key={index} src={image.branded_url} alt={image.name} />

                                    </>
                                ))}

                            </Carousel>
                            <div className="text-gray-800  bg-teal-500  text-white-100 h6" style={{padding:'15px'}}>
                                {description}
                            </div>
                            <div
                                className='justify-center items-center modal-content border-none  relative flex flex-col  pointer-events-auto  bg-clip-padding rounded-md outline-none text-current'
                            >
                                <button
                                    id='close'
                                    onClick={() => {
                                        setShowDialog(false);
                                    }}
                                    className='rounded justify-center items-center w-40 px-4 py-2 bg-teal-800 text-white text-base font-medium shadow-lg  hover:bg-teal-700 '
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className='container mx-auto'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6' >

                    {partData.map((parts) => (
                        <div
                            key={parts[0].id}
                            style={{margin: 'auto', verticalAlign: 'top' }}
                            className='align-top w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-teal-500 dark:border-gray-700'
                        >
                            <div className="grid place-items-center">
                                <img onClick={() => handleClick(parts)} className='p-8 rounded-t-lg' src={parts[0].branded_url} alt={parts[0].title}  />
                            </div>
                            <div className='px-5 pb-5'>
                                <h5 style={{ paddingBottom: '20px' }} className='text-xl font-semibold tracking-tight text-gray-900 dark:text-white'>
                                    {parts[0].title}
                                </h5>
                                <div className='flex items-center justify-between'>
                                    <span className='text-3xl font-bold text-gray-900 dark:text-teal-100'>${parts[0].price}.00</span>
                                    <a
                                        href={`mailto:parts@s4avant.com?subject=I'd like to purchase the part: ${parts[0].part_number}&body=https://s4avant.com/parts?partNumber=${parts[0].part_number}`}
                                        className='text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-teal-700 dark:hover:bg-teal-900 dark:focus:ring-blue-800'
                                    >
                                        Email to buy
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>

                </div>
            <div className='grid m-6 place-items-center'>
            <div className='justify-center items-center  '>
                <button onClick={() => handlePageChange(page - 1)} disabled={page === 0} className='px-4 py-2 mr-2 bg-teal-500 text-white font-medium rounded-lg shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-2 focus:bg-teal-300'>
                    Previous
                </button>
                <button onClick={() => handlePageChange(page + 1)} disabled={partData.length < 10} className='px-4 py-2 bg-teal-500 text-white font-medium rounded-lg shadow-sm hover:bg-teal-600
                          focus:outline-none focus:ring-2 focus:bg-teal-300'>
                    Next
                </button>
            </div>
            </div>
        </>
    );
}

export default Parts;

